<template>
  <div>
    <span v-if="Isshow" @click="show">{{showValue}}</span>
    <input v-if="!Isshow" @blur="close" v-model="newValue" />
  </div>
</template>

<script>
import { mixin } from '@/mixins/index'

export default {
  mixins: [mixin()],
  data () {
    return {
      focused: false,
      Isshow: true,
      oldValue: '',
      newValue: '',
      showValue: ''
    }
  },
  props: {
    value: [String, Number],
    url: String,
    editKey: String,
    id: [String, Number]
  },
  watch: {
    value: function (newValue) {
      this.newValue = newValue
      this.oldValue = newValue
      if (newValue === '') {
        this.showValue = '可填写'
      } else {
        this.showValue = newValue
      }
    },
    newValue (newValue) {
      typeof newValue === 'string'
        ? this.newValue = newValue.replace(/\s+/ig, '')
        : this.newValue = newValue
      if (this.editKey === 'tel') {
        this.newValue = newValue.replace(/(\s+|[^\d+])/ig, '')
      }
    }
  },
  methods: {
    show () {
      this.Isshow = !this.Isshow
    },
    close () {
      this.Isshow = !this.Isshow
      if (this.newValue === this.oldValue) {
        return true
      }
      const option = {
        id: this.id
      }
      option[this.editKey] = this.newValue
      this.postFetch(this.url, option, { needError: true, needSuccess: true }).then(response => {
        if (response.errno) {
          this.newValue = this.oldValue
        } else {
          this.oldValue = this.newValue
          this.$emit('changeDate', { value: this.newValue, id: this.id, editKey: this.editKey })
        }
        if (this.newValue === '') {
          this.showValue = '可填写'
        } else {
          this.showValue = this.newValue
        }
      })
    }
  },
  created () {
    this.oldValue = this.value
    let value
    typeof this.value === 'string'
      ? value = this.value.replace(/\s+/ig, '')
      : value = this.value
    if (value === '' || value === null) {
      this.showValue = '可填写'
    } else {
      this.showValue = value
    }
    this.newValue = value
  }
}
</script>
