<template>
  <div>
    <el-form :model="formItem" :rules="rules" ref="formItem" label-width="100px">
      <el-form-item :label="$t(filter.label)" v-for="(filter,index) in formItems" :key="index" :prop="filter.value">
        <el-select
          :remote-method="filter.remoteMethod"
          :filterable="filter.filterable"
          :remote="filter.filterable"
          :reserve-keyword="filter.filterable"
          v-model="formItem[filter.value]"
          :multiple="filter.multiple"
          v-if="filter.option">
          <el-option
            v-for="item in filter.option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-cascader
          v-else-if="filter.type === 'cascader'"
          v-model="formItem[filter.value]"
          :options="filter.cascaderOption"
          :multiple="filter.multiple"
          :props="{ expandTrigger: 'hover', emitPath: false }"
          :clearable="filter.canDelete"></el-cascader>
        <quill-editor
          v-else-if="filter.type === 'editor'"
          v-model="formItem[filter.value]"
          ref="myQuillEditor"
          :options="editorOption"
        ></quill-editor>
        <el-upload
          v-else-if="filter.type === 'image'"
          :data="{ name: filter.value }"
          action="/api/upload/index"
          list-type="picture-card"
          :multiple="true"
          :limit="filter.limit"
          :file-list="filter.fileList"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-exceed="toManayFile"
          :on-success="handleSuccess">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-date-picker
          v-else-if="filter.type === 'datetime'"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="formItem[filter.value]"
          align="right"
          :picker-options="pickerOptions"
          type="datetime">
        </el-date-picker>
        <div v-else-if="filter.type === 'hidden'"></div>
        <el-input v-model="formItem[filter.value]" :type="filter.type" v-else></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)
export default {
  mixins: [mixin(), propValue()],
  components: {
    quillEditor
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    const o = this.$route.path.split('/')
    return {
      controller: (o[1] ? o[1] : undefined),
      method: (o[2] ? o[2] : undefined),
      formItem: {
        id: 0
      },
      formItems: [],
      rules: {},
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false,
      editorOption: {
        modules: {
          ImageExtend: {
            loading: true,
            name: 'file',
            action: '/api/upload/index',
            response: (res) => {
              return this.imgUrl + '/' + res.data.respData.key
            }
          },
          toolbar: {
            container: container,
            handlers: {
              'image': function () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      }
    }
  },
  methods: {
    toManayFile () {
      this.$message({
        message: this.$t('normal.fileLimit')
      })
    },
    handleSuccess (file, fileList) {
      this.formItem[file.data.name].push(file.data.respData.key)
    },
    handleRemove (file, fileList) {
      this.formItem[file.response.data.name] = []
      for (let fileItem in fileList) {
        this.formItem[file.response.data.name].push(fileList[fileItem].response.data.respData.key)
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    onSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.postFetch('/api/' + this.controller + '/edit', this.formItem, { needSuccess: true }).then((response) => {
                this.$router.go(-1)
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  async created () {
    let props = this.$route.matched[0].props
    if (!this.$isEmpty(props)) {
      props = props.default
      if (!await this.permissionCheck(props.permission)) {
        this.$message.error(this.$t('error.1'))
      } else {
        this.$set(this.formItem, 'id', this.$route.query.id)
        this.getFetch('/api/' + this.controller + '/getone', this.formItem).then(async (response) => {
          const rules = []
          for (let prop in props) {
            switch (prop) {
              case 'formItems':
                for (let item in props.formItems) {
                  if (typeof props.formItems[item].display === 'object' && (!await this.permissionCheck(props.formItems[item].display))) continue
                  if (props.formItems[item].option) {
                    this.$set(this.formItem, props.formItems[item].value, response[props.formItems[item].value])
                    if (typeof props.formItems[item].option === 'string') {
                      props.formItems[item].option = await this.$store.dispatch('getOption', props.formItems[item].option)
                    }
                    if (props.formItems[item].filterable) {
                      if (response[props.formItems[item].value]) {
                        const r = await this.getFetch(props.formItems[item].url, { value: response[props.formItems[item].value] })
                        props.formItems[item].option.splice(0, props.formItems[item].option.length)
                        for (let i = 0; i < r.length; i++) {
                          props.formItems[item].option.push(r[i])
                        }
                      }
                    }
                  } else if (props.formItems[item].type === 'cascader') {
                    this.$set(this.formItem, props.formItems[item].value, response[props.formItems[item].value])
                    if (typeof props.formItems[item].cascaderOption === 'string') {
                      props.formItems[item].cascaderOption = await this.$store.dispatch('getCascaderOption', props.formItems[item].cascaderOption)
                    }
                  } else if (props.formItems[item].type === 'image') {
                    let fileList = []
                    let imageValue = []
                    if (response[props.formItems[item].value]) {
                      if (typeof response[props.formItems[item].value] === 'object') {
                        for (let i in response[props.formItems[item].value]) {
                          fileList.push({
                            url: this.imgUrl + '/' + response[props.formItems[item].value][i]
                          })
                        }
                        imageValue = response[props.formItems[item].value]
                      } else {
                        fileList.push({
                          url: this.imgUrl + '/' + response[props.formItems[item].value],
                          response: {
                            data: {
                              name: props.formItems[item].value
                            }
                          }
                        })
                        imageValue.push(response[props.formItems[item].value])
                      }
                      props.formItems[item].fileList = fileList
                    } else {
                      props.formItems[item].fileList = []
                    }
                    this.$set(this.formItem, props.formItems[item].value, imageValue)
                  } else {
                    this.$set(this.formItem, props.formItems[item].value, response[props.formItems[item].value])
                  }
                  this.formItems.push(props.formItems[item])
                  if (props.formItems[item].rule) {
                    rules.push({ value: props.formItems[item].rule, labal: props.formItems[item].value })
                  }
                }
                break
            }
          }
          this.rules = this.$validata.getRules(rules)
        })
      }
    } else {
      this.$message.error(this.$t('error.1'))
    }
  }
}
</script>
