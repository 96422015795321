<template>
  <div>
    <span v-if="Isshow" @click="show">{{showValue}}</span>
    <el-select v-else @change="close" @visible-change="doNull" v-model="newValue">
      <el-option
        v-for="item in newOption"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mixin } from '@/mixins/index'

export default {
  mixins: [mixin()],
  data () {
    return {
      focused: false,
      Isshow: true,
      oldValue: '',
      newValue: '',
      showValue: '',
      newOption: []
    }
  },
  props: {
    value: [String, Number],
    url: String,
    editKey: String,
    id: [String, Number],
    selectOption: {
      type: Array
    },
    indexDate: [String, Number]
  },
  created () {
    let showValue = this.value
    this.oldValue = this.value
    for (let i = 0; i < this.selectOption.length; i++) {
      if (this.selectOption[i].value === undefined) continue
      this.newOption.push(this.selectOption[i])
    }
    for (let i = 0; i < this.newOption.length; i++) {
      if (this.newOption[i].value === this.value) {
        showValue = this.newOption[i].label
      }
    }
    this.showValue = showValue
    this.newValue = this.value
  },
  watch: {
    value: function (newValue) {
      this.newValue = newValue
      this.oldValue = newValue
      for (let i = 0; i < this.newOption.length; i++) {
        if (this.newOption[i].value === this.value) {
          this.showValue = this.newOption[i].label
          break
        }
      }
    }
  },
  methods: {
    show () {
      this.Isshow = !this.Isshow
    },
    doNull (value) {
      if (!value) {
        this.Isshow = !this.Isshow
      }
    },
    close () {
      if (this.newValue === this.oldValue) {
        this.Isshow = !this.Isshow
        return true
      }
      const option = {
        id: this.id
      }
      option[this.editKey] = this.newValue
      this.postFetch(this.url, option, { needError: true, needSuccess: true }).then(response => {
        if (response.errno) {
          this.newValue = this.oldValue
        } else {
          this.oldValue = this.newValue
          this.$emit('changeDate', { value: this.newValue, id: this.id, editKey: this.editKey })
        }
        this.showValue = this.newValue
        for (let i = 0; i < this.selectOption.length; i++) {
          if (this.selectOption[i].value === this.newValue) {
            this.showValue = this.selectOption[i].label
            break
          }
        }
      })
    }
  }
}
</script>
