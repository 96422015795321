<template>
  <div>
    <div v-if="addButton || addPath" style="margin-bottom: 15px;">
      <el-button type="primary" size="small" @click.native.prevent="$router.push({ path: addPath.url, query: $route.query })" plain v-if="addPath.url">{{$t(addPath.text)}}</el-button>
      <el-button type="primary" v-for="(item, index) in addButton" :key="index" size="small" @click.native.prevent="addButtonDo(item)" plain>{{$t(item.text)}}</el-button>
    </div>
    <el-form :inline="true" :model="formInline" @submit.native.prevent size="mini">
      <el-form-item :label="$t(filter.label)" v-for="(filter,index) in filters" :key="index">
        <el-select
          v-if="filter.options"
          :remote-method="filter.remoteMethod"
          :filterable="filter.filterable"
          :remote="filter.filterable"
          :reserve-keyword="filter.filterable"
          clearable
          v-model="formInline[filter.value]"
          :multiple="filter.multiple"
          @change="onSubmit">
          <el-option
            v-for="item in filter.options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-cascader
          v-else-if="filter.type === 'cascader'"
          v-model="formInline[filter.value]"
          :options="filter.cascaderOption"
          :multiple="filter.multiple"
          :props="{ expandTrigger: 'hover', emitPath: false }"
          clearable
          @change="onSubmit"></el-cascader>
        <el-date-picker
          v-else-if="filter.type === 'datetime'"
          :value-format="filter.format ? filter.format : 'yyyy-MM-dd HH:mm:ss'"
          v-model="formInline[filter.value]"
          align="right"
          :picker-options="pickerOptions"
          :type="filter.pickerType ? filter.pickerType : 'datetimerange'"
          range-separator="-"
          @change="onSubmit">
        </el-date-picker>
        <el-date-picker
          v-else-if="filter.type === 'date'"
          :value-format="filter.format ? filter.format : 'yyyy-MM-dd'"
          v-model="formInline[filter.value]"
          align="right"
          :type="filter.pickerType ? filter.pickerType : 'date'"
          @change="onSubmit">
        </el-date-picker>
        <el-input v-model="formInline[filter.value]" :type="filter.type" v-else @keyup.enter.native="onSubmit"></el-input>
      </el-form-item>
      <el-button type="primary" size="small" @click.native.prevent="onSubmit" plain>查询</el-button>
    </el-form>
    <el-table :data="data" highlight-current-row v-loading.body="loading" element-loading-text="拼命加载中" border stripe>
      <el-table-column :prop="tableItem.value" :label="$t(tableItem.label)" v-for="(tableItem,index) in tableItems" :fixed="tableItem.fixed ? 'left' : null" :key="index" :width="tableItem.width ? tableItem.width : null">
        <template slot-scope="scope">
          <div v-if="tableItem.rewrite">
            <myChangeDate v-if="tableItem.type === 'datetime'" @changeDate="changeValue" :value="scope.row[tableItem.value]" :editKey="tableItem.value" :url="changeUrl" :id="scope.row[tableItem.pk]"></myChangeDate>
            <myChangeSelect v-else-if="tableItem.option" @changeDate="changeValue" :value="scope.row[tableItem.value]" :editKey="tableItem.value" :url="changeUrl" :id="scope.row[tableItem.pk]" :selectOption="tableItem.option"></myChangeSelect>
            <myChangeInput v-else @changeDate="changeValue" :value="scope.row[tableItem.value]" :editKey="tableItem.value" :url="changeUrl" :id="scope.row[tableItem.pk]"></myChangeInput>
          </div>
          <div v-else>
            <div v-if="tableItem.option">{{checkOption(scope.row[tableItem.value], tableItem.option)}}</div>
            <div v-else-if="tableItem.component" v-html="tableItem.component(scope.row)"></div>
            <div v-else-if="tableItem.image"><el-image @click.native="isShowImageDialog = true;showImageList = scope.row[tableItem.value]" v-for="it in scope.row[tableItem.value]" :key="it" :src="it"></el-image></div>
            <div v-else v-html="formalView(scope.row, tableItem.value)"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!$isEmpty(buttonItems)"
        :label="$t('normal.operate')"
        fixed="right"
        :width="buttonWidth(userInfo)">
        <template slot-scope="scope">
          <span v-for="(buttonItem,index) in buttonItems" :key="index">
            <el-button style="margin-left: 5px;margin-left: 5px;" type="text" v-if="(!buttonItem.showIf) || buttonItem.showIf({...scope.row, ...{userInfo}})" size="small" @click.native.prevent="buttonDone(scope.row, buttonItem)" plain>{{$t(buttonItem.buttonName)}}</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="layout-content-right">
      <el-pagination
        @current-change="changePage"
        :current-page="formInline.page"
        :page-size="formInline.limit"
        layout="total, prev, pager, next, jumper"
        :total="pageTotal">
      </el-pagination>
    </div>
    <el-dialog :visible.sync="isShowImageDialog" @closed="isShowImageDialog = false" width="90%">
      <el-carousel indicator-position="outside" style="width: 100%" height="1200px">
        <el-carousel-item v-for="src in showImageList" :key="src">
          <img :src="src" style="max-width: 100%;max-height: 100%;display: block; margin: 0 auto;"/>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import myChangeInput from '../../components/myChangeInput.vue'
import myChangeSelect from '../../components/myChangeSelect.vue'
import myChangeDate from '../../components/myChangeDate.vue'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  components: {
    myChangeInput,
    myChangeSelect,
    myChangeDate
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    const o = this.$route.path.split('/')
    const controller = (o[1] ? o[1] : undefined)
    const method = (o[2] ? o[2] : undefined)
    return {
      controller,
      method,
      loading: true,
      addPath: {},
      addButton: [],
      filters: [],
      tableItems: [],
      formInline: {
        limit: 10,
        page: 1
      },
      pageTotal: 0,
      data: [],
      buttonItems: [],
      isShowImageDialog: false,
      showImageList: [],
      changeUrl: '/api/' + controller + '/edit',
      getDataUrl: '/api/' + controller + '/index'
    }
  },
  methods: {
    changeValue (a) {
      let pk = 'id'
      for (let i = 0; i < this.tableItems.length; i++) {
        if (this.tableItems[i].value === a.editKey) {
          pk = this.tableItems[i].pk
          break
        }
      }
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i][pk] === a.id) {
          this.data[i][a.editKey] = a.value
          break
        }
      }
    },
    buttonWidth (res) {
      return 230
    },
    addButtonDo (item) {
      if (item.type === 'route') {
        this.$router.push({ path: item.path, query: item.query })
      } else if (item.type === 'download') {
        // 下载按钮
        if (item.path) {
          // 其他链接下载
          this.getFetch(item.path, this.formInline).then((response) => {
            if (item.component) {
              response = item.component(response)
            }
            let a = document.createElement('a')
            const objectUrl = URL.createObjectURL(new Blob([response], { type: 'text/csv,charset=UTF-8' }))
            a.setAttribute('href', objectUrl)
            a.setAttribute('download', new Date().toDateString() + '.csv')
            a.click()
            a.remove()
          })
        } else {
          // 正常下载
          this.loading = true
          // 表头
          let res = '\ufeff'
          for (let i = 0; i < this.tableItems.length; i++) {
            res += this.$t(this.tableItems[i].label)
            if (i === this.tableItems.length - 1) {
              res += '\n'
            } else {
              res += ','
            }
          }
          this.getFetch(this.getDataUrl, { ...this.formInline, download: true }).then(response => {
            const data = response.data
            for (let i = 0; i < data.length; i++) {
              if (typeof data[i] === 'string') {
                data[i] = JSON.parse(data[i])
              }
              // 内容
              for (let j = 0; j < this.tableItems.length; j++) {
                if (this.tableItems[j].option) {
                  res += this.checkOption(data[i][this.tableItems[j].value], this.tableItems[j].option)
                } else if (this.tableItems[j].component) {
                  res += this.tableItems[j].component(data[i][this.tableItems[j].value])
                } else if (this.tableItems[j].image) {
                  res += ''
                } else {
                  res += this.formalView(data[i], this.tableItems[j].value)
                }
                if (j === this.tableItems.length - 1) {
                  res += '\n'
                } else {
                  res += ','
                }
              }
            }
            if (item.component) {
              res = item.component(res)
            }
            let a = document.createElement('a')
            const objectUrl = URL.createObjectURL(new Blob([res], { type: 'text/csv,charset=UTF-8' }))
            a.setAttribute('href', objectUrl)
            a.setAttribute('download', new Date().toDateString() + '.csv')
            a.click()
            a.remove()
            this.loading = false
          })
        }
      } else if (item.type === 'popup') {
        this.getFetch(item.path, this.formInline).then((response) => {
          this.$notify(item.component(response))
        })
      } else if (item.type === 'ajax') {
        this.getFetch(item.path, this.formInline).then((response) => {
          this.getData()
        })
      }
    },
    formalView (row, value) {
      value = value.split('|')
      let word = ''
      for (let i in value) {
        if (row[value[i]] || row[value[i]] === 0) {
          word += row[value[i]]
        }
      }
      return word
    },
    getUrl (path, row) {
      let word = /%\S*?%/.exec(path)
      if (word === null) {
        return path
      } else {
        const v = this.$validator.trim(word[0], '%')
        if (row[v]) {
          path = path.replace(/%\S*?%/, row[v])
        } else {
          path = path.replace(/%\S*?%/, '')
        }
        return this.getUrl(path, row)
      }
    },
    buttonDone (row, buttonItem) {
      if (buttonItem.type === 'ajax') {
        this.getFetch(this.getUrl(buttonItem.path, row), null, { needSuccess: true }).then(response => {
          this.getData()
        })
      } else if (buttonItem.type === 'route') {
        this.$router.push(this.getUrl(buttonItem.path, row))
      } else if (buttonItem.type === 'contentAjax') {
        this.$prompt(this.$t(buttonItem.inputTitle), this.$t('normal.tip'), {
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        }).then(({ value }) => {
          this.getFetch(this.getUrl(buttonItem.path, row) + '&content=' + value, null, { needSuccess: true }).then(response => {
            this.getData()
          })
        })
      } else if (buttonItem.type === 'jump') {
        window.location.href = buttonItem.path
      }
    },
    checkOption (value, option) {
      for (let i = 0; i < option.length; i++) {
        if (option[i].value === value) {
          return option[i].label
        }
      }
    },
    onSubmit () {
      this.formInline.page = 1
      this.getData()
    },
    changePage (page) {
      this.formInline.page = page
      this.getData()
    },
    getData () {
      const item = this.controller + this.method
      this.getFetch(this.getDataUrl, this.formInline).then(response => {
        this.pageTotal = Number(response.count)
        if (response.data.length === 0 && this.pageTotal > 1) {
          this.formInline.page = 1
          this.getData()
        } else {
          this.data = []
          for (let i = 0; i < response.data.length; i++) {
            if (typeof response.data[i] === 'string') {
              this.$set(this.data, i, JSON.parse(response.data[i]))
            } else {
              this.$set(this.data, i, response.data[i])
            }
          }
          this.$localStorage.set(item, JSON.stringify(this.formInline))
          this.formInline.page = this.formInline.page
          this.loading = false
        }
      })
    },
    clearData () {
      const o = this.$route.path.split('/')
      this.controller = (o[1] ? o[1] : undefined)
      this.method = (o[2] ? o[2] : undefined)
      this.addPath = {}
      this.formInline = {
        limit: 10,
        page: 1
      }
      this.loading = true
      this.filters = []
      this.tableItems = []
      this.pageTotal = 0
      this.data = []
      this.buttonItems = []
    },
    async install () {
      this.clearData()
      let props = this.$route.matched[0].props
      if (!this.$isEmpty(props)) {
        // 初始化
        this.changeUrl = '/api/' + this.controller + '/edit'
        this.getDataUrl = '/api/' + this.controller + '/index'
        props = props.default
        if (!await this.permissionCheck(props.permission)) {
          this.$message.error(this.$t('error.1'))
        } else {
          let formValue = this.$localStorage.get(this.controller + this.method)
          if (formValue) {
            formValue = JSON.parse(formValue)
          }
          if (formValue && formValue.limit) this.formInline.limit = formValue.limit
          if (formValue && formValue.page) this.formInline.page = formValue.page
          for (let prop in props) {
            switch (prop) {
              case 'addPath':
                if (props.addPath && ((props.addPath.display && await this.permissionCheck(props.addPath.display)) || !props.addPath.display)) {
                  const addPath = { ...props.addPath }
                  if (!addPath.url) {
                    addPath.url = '/' + this.controller + '/add'
                  }
                  if (!addPath.text) {
                    addPath.text = 'normal.add'
                  }
                  this.addPath = addPath
                }
                break
              case 'addButton':
                if (props.addButton) {
                  const addButton = []
                  for (let i = 0; i < props.addButton.length; i++) {
                    if ((props.addButton[i].display && await this.permissionCheck(props.addButton[i].display)) || !props.addButton[i].display) {
                      addButton.push(props.addButton[i])
                    }
                  }
                  this.addButton = addButton
                }
                break
              case 'filters':
                for (let item in props.filters) {
                  if (typeof props.filters[item].display === 'object' && (!await this.permissionCheck(props.filters[item].display))) continue
                  if (props.filters[item].option) {
                    if (typeof props.filters[item].option === 'string') {
                      props.filters[item].option = await this.$store.dispatch('getOption', props.filters[item].option)
                    }
                    const option = [...props.filters[item].option]
                    if (props.filters[item].multiple) {
                      this.$set(this.formInline, props.filters[item].value, [])
                    } else if (props.filters[item].filterable) {
                      this.$set(this.formInline, props.filters[item].value, props.filters[item].default)
                    } else {
                      option.unshift({ label: this.$t('normal.all') })
                      this.$set(this.formInline, props.filters[item].value, props.filters[item].default)
                    }
                    if (!props.filters[item].filterable) {
                      props.filters[item].options = option
                    } else {
                      props.filters[item].options.splice(0, props.filters[item].options.length)
                    }
                  }
                  if (props.filters[item].type === 'cascader') {
                    if (typeof props.filters[item].cascaderOption === 'string') {
                      props.filters[item].cascaderOption = await this.$store.dispatch('getCascaderOption', props.filters[item].cascaderOption)
                    }
                    const cascaderOption = [...props.filters[item].cascaderOption]
                    if (props.filters[item].multiple) {
                      this.$set(this.formInline, props.filters[item].value, [])
                    } else {
                      this.$set(this.formInline, props.filters[item].value, props.filters[item].default)
                    }
                    props.filters[item].cascaderOption = cascaderOption
                  }
                  // 从localstroge取出数据
                  if (formValue && formValue[props.filters[item].value]) {
                    if (props.filters[item].filterable) {
                      if (formValue[props.filters[item].value]) {
                        const r = await this.getFetch('/api/user/getSomeOne', { value: formValue[props.filters[item].value] })
                        props.filters[item].options.splice(0, props.filters[item].options.length)
                        props.filters[item].options.push({ label: '全部' })
                        for (let i = 0; i < r.length; i++) {
                          props.filters[item].options.push(r[i])
                        }
                      }
                    }
                    // 如果未hidden则不取出缓存数据
                    if (props.filters[item].type !== 'hidden') {
                      this.$set(this.formInline, props.filters[item].value, formValue[props.filters[item].value])
                    }
                  }
                  if (this.$route.query[props.filters[item].value]) {
                    if (isNaN(parseInt(this.$route.query[props.filters[item].value]))) {
                      this.$set(this.formInline, props.filters[item].value, this.$route.query[props.filters[item].value])
                    } else {
                      this.$set(this.formInline, props.filters[item].value, parseInt(this.$route.query[props.filters[item].value]))
                    }
                  }
                  this.filters.push(props.filters[item])
                }
                break
              case 'tableItems':
                for (let item in props.tableItems) {
                  if (typeof props.tableItems[item].display === 'object' && (!await this.permissionCheck(props.tableItems[item].display))) continue
                  if (typeof props.tableItems[item].rewrite === 'object') {
                    props.tableItems[item].rewrite = await this.permissionCheck(props.tableItems[item].rewrite)
                  }
                  if (props.tableItems[item].option && typeof props.tableItems[item].option === 'string') {
                    props.tableItems[item].option = await this.$store.dispatch('getOption', props.tableItems[item].option)
                  }
                  this.tableItems.push(props.tableItems[item])
                }
                break
              case 'buttonItems':
                for (let item in props.buttonItems) {
                  if (typeof props.buttonItems[item].display === 'object' && (!await this.permissionCheck(props.buttonItems[item].display))) continue
                  this.buttonItems.push(props.buttonItems[item])
                }
                break
              case 'buttonWidth':
                this.buttonWidth = props.buttonWidth
                break
              case 'changeUrl':
                this.changeUrl = props.changeUrl
                break
              case 'getDataUrl':
                this.getDataUrl = '/api/' + props.getDataUrl
                break
              default:
                this[prop] = props[prop]
            }
          }
          if (!props.addButton) {
            this.addButton = []
          }
          if (!props.buttonWidth) {
            this.buttonWidth = res => {
              return 230
            }
          }
          this.getData()
        }
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.install()
    }
  },
  created () {
    this.install()
  }
}
</script>
