<template>
  <div>
    <div v-if="oldValue==='nulls'"></div>
    <div v-if="oldValue!=='nulls'">
      <span v-if="Isshow" @click="show">{{newValue}}</span>
      <el-date-picker
        value-format="yyyy-MM-dd HH:mm:ss"
        :clearable="false"
        v-else
        v-model="newValue"
        type="datetime"
        placeholder="选择日期时间"
        align="right"
        :picker-options="pickerOptions"
        @change="close">
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import { mixin } from '@/mixins/index'

export default {
  mixins: [mixin()],
  data () {
    return {
      focused: false,
      Isshow: true,
      oldValue: '',
      newValue: '',
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },
  props: {
    value: [String, Number],
    url: String,
    editKey: String,
    id: [String, Number]
  },
  mounted: function () {
    this.$nextTick(function () {
      this.newValue = this.value
      this.oldValue = this.value
    })
  },
  watch: {
    value: function (newValue) {
      this.newValue = this.value
      this.oldValue = this.value
    }
  },
  methods: {
    show () {
      this.Isshow = !this.Isshow
    },
    close () {
      this.Isshow = !this.Isshow
      if (this.newValue === this.oldValue) {
        return true
      }
      const option = {
        id: this.id
      }
      option[this.editKey] = this.newValue
      this.postFetch(this.url, option, { needError: true, needSuccess: true }).then(response => {
        if (response.errno) {
          this.newValue = this.oldValue
        } else {
          this.oldValue = this.newValue
          this.$emit('changeDate', { value: this.newValue, id: this.id, editKey: this.editKey })
        }
      })
    }
  }
}
</script>
